import React, { useState, useEffect } from "react";
import styles from './Banner.module.css'
import { useTranslation } from "react-i18next";

const Banner = ({ searchTerm, handleSearchChange, onSearchClick }) => {
    const { t } = useTranslation();
    const [stickyClass, setStickyClass] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => window.removeEventListener('scroll', stickNavbar);
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            // window height changed for the demo
            windowHeight > 50 ? setStickyClass('sticky-nav') : setStickyClass('');
        }
    };

    return (
        <div className={`navbar ${stickyClass}`}>
            <div className={`${styles.headerPage} headerNav`}>
                <div className="container">
                    Search
                </div>
            </div>
        </div>)
}
export default Banner

