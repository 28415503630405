import React, {  } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next";
import styles from "./MotherInfo.module.css"
const MotherInfo = () => {
    const { t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleCloseDialog = () => {
        setOpen(false);
      };

    return (
        <>
        <div>
            <InfoIcon onClick={handleClickOpen} className="infoColor"></InfoIcon> <span className={styles.inforText}>{t('example')}: កែវ</span>
        </div>
           
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('monther_family_name')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       <p>{t('monther_family_name_note')}</p>
                       <p>{t('example')}: {t('sample_sur_name')}</p> 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{t('close')}</Button>
                </DialogActions>
            </Dialog></>);
}
export default MotherInfo