import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React,{createContext, useContext, useEffect, useMemo, useState} from "react";
import Layout from './pages/Layout';
import Home from './pages/Home';
import NoPage from './pages/NoPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApiContext, defaultApiService } from './services/context.js'
export const AppContext = createContext({
  title: "",
  setTitle: () => { }
})

export function useTitle(value) {
  const { setTitle, title } = useContext(AppContext);
  useEffect(() => {
    setTitle(value)
  }, [])

  return { setTitle, title }
}

function App() {
  const [title, setTitle] = useState("");
  const value = useMemo(
    () => ({ title, setTitle }),
    [title]
  )
  return (
    <ApiContext.Provider value={defaultApiService}>
      <AppContext.Provider value={value}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index path='/' element={<Home />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </ApiContext.Provider >
  );
}

export default App;
