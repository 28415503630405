import { createContext, useContext } from "react";
import ApiService from "./api.js";

const BASE_URL =  "https://api.verify.gov.kh" // UAT: "https://api-ds-uat.azurewebsites.net"

console.log({ BASE_URL })

export const defaultApiService = new ApiService(BASE_URL);

export const ApiContext = createContext(defaultApiService);

export function useApi() {
  return useContext(ApiContext);
}

