import React, {  } from "react";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from "react-i18next";
import styles from "./ToggleButtonSwitch.module.css"
import { hasCertificate, hasNoCertificate } from "../../util/staticdata";
const ToggleButtonSwitch = (props) => {
    const { t} = useTranslation();
    return (
        <div className={styles.toggleBox}>
            <p className={styles.toggleNote}>{t('search_toggle_note')}</p>
            <ToggleButtonGroup
                value={props.value}
                exclusive
                fullWidth
                size="small"
                className={styles.toggleToggle}
                onChange={props.handleToggleChange}
                aria-label="text alignment">
                <ToggleButton value={hasCertificate} aria-label="left aligned" classes={{ selected: styles.buttonColor }}>
                    {t('has')}
                </ToggleButton>
                <ToggleButton value={hasNoCertificate} aria-label="right aligned"  classes={{ selected: styles.buttonColor }}>
                {t('not_has')}
                </ToggleButton>
            </ToggleButtonGroup>
            
        </div>);
}
export default ToggleButtonSwitch