import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './Header.module.css'
const Header = () => {
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState('km');
    useEffect(() => {
        // const lang = i18n.language;
        // setLang(lang);
    }, []);
    const changeLanguageHandler = (lang) => {
        i18n.changeLanguage(lang);
        setLang(lang);
        localStorage.setItem("lng", lang);
    }

    return (
        <div className={`${styles.headerPage}`}>
            <div className='container navigationBar'>
                <div className={styles.moyesLogoBox}>
                    <img src="/moys-logo-xl.png" className={`marginlogo ${styles.moyesLogo}`} alt={t('moyes')}></img>
                    <img src="/mptc-logo.png" className={`mptc ${styles.moyesLogo}`} alt={t('mptc')}></img>
                    <div className={styles.moyesTitle}>{t('moyes')}</div>
                </div>
                <div className={styles.languatNav}>
                    <ul className={styles.leftNav}>
                        <li>
                            <div className={i18n.language === "km" ? 'selected' : ''} onClick={() => {
                                changeLanguageHandler('km');
                            }}><img src="/km.svg" className={styles.topIcon} alt="KH"></img></div>
                        </li>
                        <li>
                            <div className={i18n.language === "en" ? 'selected' : ''} onClick={() => {
                                changeLanguageHandler('en');
                            }}><img src="/en.svg" className={styles.topIcon} alt="EN"></img></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>);
}
export default Header