import React from "react";
import { useTranslation } from "react-i18next";
const Notfound = () => {
    const { t } = useTranslation();
    return (
        <div className={`notFout`}>
            <div className="container">
                <h3>{t('message_not_found')}</h3>
                <p>
                    {t('message_not_found_detail')}
                </p>
            </div>
        </div>)
}
export default Notfound
