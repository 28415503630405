import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import styles from "../App.css"
const Layout = () => {
    return (
        <div className={styles.skaitemplate}>
            <Header></Header>
            <div>
                    <Outlet />
            </div>
        </div>
    )
};

export default Layout;