import React, {  } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next";
import styles from "./LetterNumber.module.css"
const LetterNumber = () => {
    const { t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleCloseDialog = () => {
        setOpen(false);
      };

    return (
        <>
        <div>
            <InfoIcon onClick={handleClickOpen} className="infoColor"></InfoIcon> <span className={styles.inforText}>{t('example')}: 39329</span>
        </div>
           
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('letter_number')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <img src="/letter-number.png" alt={t('letter_number')}></img>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{t('close')}</Button>
                </DialogActions>
            </Dialog></>);
}
export default LetterNumber